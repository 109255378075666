import React from "react";

import ModalContainer from "../../ModalParts/ModalContainer";
import GuideModalBody from "../../ModalParts/ModalBodies/GuideModalBody";

const GuideModalComponent = ({
  exJourneyId,
  handleModalClose,
  journey,
  user,
  guideConfig,
  partner,
  premiumProgram,
  headerType
}) => {
  return (
    <ModalContainer handleClose={handleModalClose}>
      <GuideModalBody
        {...{
          exJourneyId,
          journey,
          user,
          guideConfig,
          partner,
          premiumProgram,
          headerType,
          handleModalClose
        }}
      />
    </ModalContainer>
  );
};

export default GuideModalComponent;
