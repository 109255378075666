export const nameString = name => {
  if (typeof name === "string") return name;
  if (!name) return;
  let { first, nickname, middle, last, prefix, suffix } = name;
  nickname = nickname && `"${nickname}"`;
  return [prefix, first, nickname, middle, last, suffix]
    .filter(Boolean)
    .join(" ");
};

// gatsby images are handled via an image object containing various img properties
// this function helps simplify the extraction of the url
export const getGatsbyImgSrc = gatsbyImgObj => {
  if (!gatsbyImgObj) return "";

  return !!gatsbyImgObj.childImageSharp
    ? gatsbyImgObj.childImageSharp.fluid.src
    : gatsbyImgObj;
};
