import React from "react";

import { getGatsbyImgSrc, nameString } from "../../../utils/utils";

import "./AdCTA.sass";
import qs from "querystring";

const AdCTA = ({
  header,
  description,
  memorialUrl,
  image,
  buttonText,
  buttonAction: action,
  buttonUrl = "",
  flowerUrl,
  isMobile,
  user,
  guideConfig,
  quizConfig,
  partner,
  premiumProgram,
  trackingStage,
  handleOpenModal,
  clickId = ""
}) => {
  const imgUrl = getGatsbyImgSrc(image);

  const { _id, name, email, phone } = user || {};
  const { quizUrl, sourceId } = quizConfig || {};

  const queryString = qs.stringify({
    ...(user && { user: _id }),
    ...(action && { action }),
    skipMat: true
  });

  const memUrlWithQuery = memorialUrl
    ? `https://everdays.com${memorialUrl}?${queryString}`
    : "https://everdays.com";

  const quizQuery = qs.stringify({
    name: (name && name.first) || "",
    email,
    phone,
    userid: _id,
    stage: trackingStage,
    fullname: nameString(name),
    premiumProgram,
    partner,
    sourceId
  });

  // set cta URL initially
  let ctaUrl = memUrlWithQuery;
  switch (action) {
    case "flowers":
      ctaUrl = !!flowerUrl ? flowerUrl : memUrlWithQuery;
      break;
    case "quiz":
      ctaUrl = `${quizUrl}?${quizQuery}`;
      break;
    default:
      break;
  }

  // try using buttonUrl first, default to ctaUrl
  const ctaHref = buttonUrl || ctaUrl;

  const handleCTAClick = e => {
    if (["guide", "set-up-call"].includes(action)) {
      e.preventDefault();
      handleOpenModal();
    }
  };

  const shouldRenderCTAButton = !!buttonUrl || !!buttonText;

  return (
    <div className="ad-cta">
      <img src={imgUrl} alt="Ad CTA" />
      <div className="ad-cta-content">
        <h3>{header}</h3>
        <p>{description}</p>
        {shouldRenderCTAButton && (
          <a
            id={clickId || ""}
            href={ctaHref}
            target="_blank"
            rel="noreferrer noopener"
            onClick={handleCTAClick}
          >
            <button id={clickId ? `${clickId}-btn` : ""}>{buttonText}</button>
          </a>
        )}
      </div>
    </div>
  );
};

export default AdCTA;
