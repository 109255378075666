import React from "react";
import classNames from "classnames";
import qs from "querystring";

import { nameString } from "../../../utils/utils";

import chevronRight from "../../../img/icons/chevron-right.svg";
import silhouette from "../../../img/icons/silhouetto-of-a-man.svg";

import "./JourneyNav.sass";

const JourneyNav = ({
  decedentName,
  memorialImg,
  memorialUrl,
  user,
  action,
  handleOpenModal,
  text,
  ctaClickId = "",
  isScrolled,
  navClickId
}) => {
  const fullMemorialUrl = `https://everdays.com${memorialUrl}`;

  const { _id } = user || {};

  const queryString = qs.stringify({
    ...(user && { user: _id }),
    ...(action && { action }),
    skipMat: true
  });

  const ctaUrl = memorialUrl
    ? `https://everdays.com${memorialUrl}?${queryString}`
    : "https://everdays.com";

  const handleCTAClick = e => {
    if (["guide", "set-up-call"].includes(action)) {
      e.preventDefault();
      handleOpenModal();
    }
  };

  const journeyNavClasses = classNames("journey-nav", {
    "is-scrolled": isScrolled
  });

  const name = !!decedentName ? (
    <span className="full-name">{nameString(decedentName)}</span>
  ) : (
    <span className="name-placeholder" />
  );

  const decedentImage = memorialImg || silhouette || "";

  return (
    <div className={journeyNavClasses}>
      <div className="nav-container">
        {ctaUrl && text && (
          <a
            href={ctaUrl}
            target="_blank"
            rel="noreferrer noopener"
            onClick={handleCTAClick}
            className="header-cta"
          >
            <div
              className="cta-button"
              id={ctaClickId ? `${ctaClickId}-desktop` : ""}
            >
              <p>{text}</p>
            </div>
          </a>
        )}
        <a
          href={fullMemorialUrl}
          target="_blank"
          rel="noreferrer noopener"
          className="memorial-link"
        >
          <div className="memorial-cta-wrapper" id={navClickId || ""}>
            <div
              className="decedent-image"
              style={{ backgroundImage: `url('${decedentImage}')` }}
            />
            <div className="decedent-name">
              <span>Memorial</span>
              {name}
            </div>
            <img className="chevron" src={chevronRight} alt="right chevron" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default JourneyNav;
