import React from "react";
import classNames from "classnames";
import MarkdownContent from "../../utils/MarkdownContent";

import { getGatsbyImgSrc } from "../../../utils/utils";

import "./ContentItem.sass";

const ContentItem = ({ image, title, content, introText, isFirstItem }) => {
  const imgSrc = getGatsbyImgSrc(image);

  const contentItemClasses = classNames("content-item", {
    "is-first-item": isFirstItem
  });

  return (
    <div className={contentItemClasses}>
      {!!introText && <p className="intro-text">{introText}</p>}
      {!!imgSrc && <img src={imgSrc} alt="journey content" />}
      {!!title && (
        <div className="heading">
          <h2>{title}</h2>
        </div>
      )}
      <p className="item-body">
        <MarkdownContent content={content} />
      </p>
    </div>
  );
};

export default ContentItem;
