import React from "react";
import Slider from "react-slick";

import { getGatsbyImgSrc } from "../../../utils/utils";

import "./Carousel.sass";

const Carousel = ({ image1, image2, image3, image4, image5, isMobile }) => {
  const imageArray = [image1, image2, image3, image4, image5]
    .filter(Boolean)
    .map(img => getGatsbyImgSrc(img));

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    variableWidth: false,
    centerMode: true,
    centerPadding: "50px"
  };

  const ImageCarousel = (
    <Slider className="carousel" {...settings}>
      {imageArray.map(img => (
        <img src={img} alt="Mobile Carousel Element" />
      ))}
    </Slider>
  );

  const DesktopView = (
    <div className="desktop-carousel">
      {imageArray.map(img => (
        <img src={img} alt="Desktop Carousel Element" />
      ))}
    </div>
  );

  return isMobile ? ImageCarousel : DesktopView;
};

export default Carousel;
