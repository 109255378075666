import React from "react";

import AppLinks from "../AppLinks";

import "./Footer.sass";

const logo =
  "https://everdays-site.s3.amazonaws.com/shared/logos/everdays-logo-full-blue-providedby-2.svg";

const Footer = props => {
  return (
    <div className="footer-container">
      <div className="pages-footer">
        <div className="footer-links">
          <div className="logo-wrapper">
            <img src={logo} alt="" />
          </div>
          <ul className="everdays-links">
            <li>
              <a
                id={`cms_footer_contact_us`}
                href="mailto:support@everdays.com"
              >
                Contact Us
              </a>
            </li>
            <li>
              <a id={`cms_footer_tos`} href="https://everdays.com/terms">
                TOS
              </a>
            </li>
            <li>
              <a
                id={`cms_footer_privacy_policy`}
                href="https://everdays.com/privacy"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="links-downloads-wrapper">
          <div className="phone-wrapper">
            {/*<div className="phone">USA & Rest of World: +1 (248) 480-2444</div>*/}
          </div>
          <div className="download-links">
            <AppLinks isRow />
          </div>
        </div>
        {/*
          <div className="mobile-app-buttons mobile-only">
            <AppLinks currentPage={currentPage} />
          </div> */}
      </div>
      <div className="legal-section">
        <div className="legal-wrapper">
          <p className="legal">
            <p className="sub-legal">
              © {new Date().getFullYear()} Everdays, Inc. All rights reserved.
            </p>
            <p className="sub-legal">
              The Everdays mark and the Everdays logo are registered trademarks
              of Everdays, Inc.
            </p>
          </p>
        </div>
        <p className="sub-legal-footnote">
          * This app is owned and operated by Everdays, Inc., a Delaware
          corporation doing business in California as Everdays Moments Insurance
          Marketing.
        </p>
      </div>
    </div>
  );
};

export default Footer;
