import React, { useState, useEffect } from "react";
import { throttle } from "lodash";

const mobileBreakpoint = 768;

const JourneyWrapper = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [exJourneyId, setExJourneyId] = useState("");

  // on mount: attach scroll listener
  useEffect(() => {
    const scrollFn = () => {
      window.scrollY > 0 ? setIsScrolled(true) : setIsScrolled(false);
    };
    const throttleScroll = throttle(scrollFn, 200);

    if (global.window) {
      window.addEventListener("scroll", throttleScroll);
    }

    return () => {
      window.removeEventListener("scroll", throttleScroll);
    };
  }, []);

  // on mount: set isMobile state
  useEffect(() => {
    if (global.window) {
      const isMobile = window.innerWidth < mobileBreakpoint;
      setIsMobile(isMobile);
    }
  }, []);

  // on mount: add event listener to detect window resize and set state accordingly
  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerWidth < mobileBreakpoint);
    const throttledResize = throttle(handleResize, 200);

    if (global.window) {
      window.addEventListener("resize", throttledResize);
    }

    return () => {
      window.removeEventListener("resize", throttledResize);
    };
  }, []);

  // on mount, make a request to the API to get the journey data
  useEffect(() => {
    const fetchJourney = async () => {
      const url = "https://everdays.com/public/journey/external";
      const opts = { method: "GET" };

      const searchParams = new URLSearchParams(
        window && window.location && window.location.search
      );
      const id = searchParams && searchParams.get("id");

      if (!id) return;

      setExJourneyId(id);

      const res = await fetch(`${url}/${id}`, opts);
      const resJson = await res.json();

      if (resJson && resJson.data) {
        setRequestData(resJson.data);
      }
    };

    fetchJourney();
  }, []);

  return (
    <div className="journey-wrapper">
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          ...requestData,
          isMobile,
          isScrolled,
          exJourneyId
        })
      )}
    </div>
  );
};

export default JourneyWrapper;
