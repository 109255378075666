import React from "react";

import "./ButtonLink.sass";

const ButtonLink = ({ action, clickId = "", handleOpenModal, url, text }) => {
  const handleButtonClick = e => {
    if (["guide", "set-up-call"].includes(action)) {
      e.preventDefault();
      handleOpenModal();
    }
  };

  return (
    <div className="button-link-container">
      <a
        className="button-link"
        href={url || "#"}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleButtonClick}
      >
        <button id={clickId}>{text}</button>
      </a>
    </div>
  );
};

export default ButtonLink;
