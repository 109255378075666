import React from "react";

import ModalContainer from "../../ModalParts/ModalContainer";
import SetUpCallModalBody from "../../ModalParts/ModalBodies/SetUpCallModalBody";

const SetUpCallModalComponent = ({
  exJourneyId,
  handleModalClose,
  user,
  guideConfig,
  premiumProgram
}) => {
  return (
    <ModalContainer handleClose={handleModalClose}>
      <SetUpCallModalBody
        {...{
          exJourneyId,
          user,
          guideConfig,
          premiumProgram,
          handleModalClose
        }}
      />
    </ModalContainer>
  );
};

export default SetUpCallModalComponent;
