import React from "react";

import closeIcon from "../../../img/icons/close-icon.png";

import "./ModalContainer.sass";

const ModalContainer = ({ children, handleClose }) => {
  return (
    <div className="modal-container">
      <div className="modal-overlay" />
      <div className="modal-body">
        <div
          className="modal-close-button"
          onClick={handleClose}
          role="button"
          tabIndex="-1"
          onKeyDown={handleClose}
        >
          <img src={closeIcon} alt="Modal close icon" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default ModalContainer;
