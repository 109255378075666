import React from "react";

import "./Sender.sass";

const defaultLogo =
  "https://everdays-site.s3.amazonaws.com/shared/logos/everdays-logo-mascot-round-blue.png";

const Sender = ({ journey, hideText = false }) => {
  const { externalMessage = "", sender } = journey || {};
  const { image = "", name = "", funeralHomeName = "" } = sender || {};
  return !!name ? (
    <div className="sender">
      {/*<div className="afp-section">*/}
      {/*  <img src={image || defaultLogo} alt="Funeral Home Director / AFP" />*/}
      {/*  <div className="afp-info">*/}
      {/*    <span className="afp-name">{name}</span>*/}
      {/*    <span className="funeral-home-name">{funeralHomeName}</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {!hideText && <div className="sender-intro">{externalMessage}</div>}
    </div>
  ) : (
    ""
  );
};

export default Sender;
