import React from "react";

import { getGatsbyImgSrc } from "../../../utils/utils";

import "./Infographic.sass";

const Infographic = ({ infographicImage }) => {
  const imgUrl = getGatsbyImgSrc(infographicImage);

  return (
    <div className="infographic">
      <img src={imgUrl} alt="Journey Infographic" />
    </div>
  );
};

export default Infographic;
