import React, { useState, useEffect } from "react";

import { nameString } from "../../../../../utils/utils";

import "./GuideModalBody.sass";

import guideImg from "../../../../../img/modal/get-guide-image.png";
import successIcon from "../../../../../img/icons/temp-success-icon.png";

const GuideModalBody = ({
  exJourneyId,
  journey,
  user,
  partner,
  premiumProgram = "",
  headerType = "image",
  buttonText = "Email My Journal",
  guideConfig = {},
  onSubmitSuccess = () => {},
  onSubmitFailure = () => {},
  handleModalClose
}) => {
  const { name = "", email = "", phone = "" } = user || {};
  const { formName, mandrillTemplate, sourceId, tracking, assetUrl } =
    guideConfig || {};

  const [currentBody, setCurrentBody] = useState("guide-form");
  const [hasDownloadStarted, setHasDownloadStarted] = useState(false);
  const [wasGuideSent, setWasGuideSent] = useState(false);
  const [formObj, setFormObj] = useState({
    name: "",
    email: "",
    phone: ""
  });

  // on mount, if all info is provided, show the info-known modal view
  useEffect(() => {
    if (!!name && !!email && !!phone) {
      setCurrentBody("info-known");
    }
  }, [name, email, phone]);

  // pre-populate the form inputs with the user data if possible
  useEffect(() => {
    const newFormObj = {
      ...formObj,
      ...(name && { name: nameString(name) }),
      ...(email && { email }),
      ...(phone && { phone })
    };

    setFormObj(newFormObj);
    // eslint-disable-next-line
  }, [user, name, email, phone]); // omit formObj from dep array to prevent loop

  const handleChange = (e, key) => {
    const newFormObj = { ...formObj };
    newFormObj[key] = e.target.value;

    setFormObj(newFormObj);
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    const submitObj = {
      ...formObj,
      id: exJourneyId,
      tos: true,
      form: formName,
      sourceId: Number.parseInt(sourceId),
      tracking,
      mandrillTemplate,
      ...(premiumProgram && { premiumProgram })
    };

    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(submitObj)
    };

    const res = await fetch(
      "https://everdays.com/public/journey/external/form",
      opts
    );
    if (res.status === 200) {
      onSubmitSuccess();
    } else {
      onSubmitFailure();
    }
  };

  const handleSend = e => {
    setCurrentBody("guide-sent");
    setWasGuideSent(true);
    handleFormSubmit(e);
  };

  const handleDownload = () => {
    setCurrentBody("download-starting");
    setHasDownloadStarted(true);
    assetUrl && window.open(assetUrl);
  };

  const renderHeader = () => {
    switch (headerType) {
      case "image":
        return (
          <img
            className="guide-image"
            src={guideImg}
            alt="Get Your Free Guide - Everdays"
          />
        );
      case "text":
        return (
          <div className="guide-form-text-header">
            <h2>Your Free Planning Journal</h2>
            <p>
              Add your details below and we'll send your journal right away. We
              look forward to connecting with you soon to answer any questions
              you may have.
            </p>
          </div>
        );
      default:
        break;
    }
  };

  const GuideForm = (
    <form className="guide-form" onSubmit={handleSend}>
      {renderHeader()}
      <label htmlFor="guide-form-email">Email Address *</label>
      <input
        id="guide-form-email"
        name="email"
        type="email"
        placeholder="Your Email"
        value={formObj.email}
        onChange={e => handleChange(e, "email")}
        required
      />
      <label htmlFor="guide-form-name">Name</label>
      <input
        id="guide-form-name"
        name="name"
        placeholder="Your Name"
        value={formObj.name}
        onChange={e => handleChange(e, "name")}
      />
      <label htmlFor="guide-form-phone">Phone</label>
      <input
        id="guide-form-phone"
        name="phone"
        type="tel"
        placeholder="Your Phone Number"
        value={formObj.phone}
        onChange={e => handleChange(e, "phone")}
      />
      <button type="submit">{buttonText}</button>
    </form>
  );

  const GuideSent = (
    <div className="guide-sent">
      <h2>Your Journal Has Been Sent</h2>
      <img
        className="success-icon"
        src={successIcon}
        alt="Guide Sent Success Icon"
      />
      <p>
        We've sent your free Planning Journal to{" "}
        <strong>{formObj.email}</strong>
      </p>
      <button onClick={handleModalClose}>Done</button>
      {!hasDownloadStarted && (
        <button onClick={handleDownload} className="secondary">
          Download A Copy Now
        </button>
      )}
    </div>
  );

  const InfoKnownBody = (
    <div className="info-known-body">
      <h2>Your Free Planning Journal</h2>
      <p>We look forward to connecting with you soon.</p>
      <button onClick={handleSend}>Email My Journal</button>
      <button onClick={handleDownload} className="secondary">
        Or, Download Journal Now
      </button>
    </div>
  );

  const DownloadStarting = (
    <div className="download-starting">
      <h2>Downloading Now</h2>
      <img
        className="success-icon"
        src={successIcon}
        alt="Download Start Success Icon"
      />
      <p>
        Your free planning journal should be starting to download. We can also
        email you a copy if you'd like.
      </p>
      {!wasGuideSent && (
        <button onClick={handleSend}>Email My Planning Journal</button>
      )}
      <button className="secondary" onClick={handleModalClose}>
        Done
      </button>
    </div>
  );

  const renderModalBody = () => {
    switch (currentBody) {
      case "guide-form":
        return GuideForm;
      case "guide-sent":
        return GuideSent;
      case "info-known":
        return InfoKnownBody;
      case "download-starting":
        return DownloadStarting;
      default:
        break;
    }
  };

  return <div className="guide-modal-body">{renderModalBody()}</div>;
};

export default GuideModalBody;
