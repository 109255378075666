import React from "react";

import "./QuoteComponent.sass";

const QuoteComponent = ({ quoteText }) => {
  return (
    <div className="quote-component">
      <p className="quote-text">{quoteText}</p>
    </div>
  );
};

export default QuoteComponent;
