import React from "react";
import classNames from "classnames";
import qs from "querystring";

import "./FixedFooterCTA.sass";

const FixedFooterCTA = ({
  memorialUrl = "",
  user,
  action,
  text,
  handleOpenModal,
  clickId = "",
  isScrolled
}) => {
  const { _id } = user || {};

  const queryString = qs.stringify({
    ...(user && { user: _id }),
    ...(action && { action }),
    skipMat: true
  });

  const ctaUrl = memorialUrl
    ? `https://everdays.com${memorialUrl}?${queryString}`
    : "https://everdays.com";

  const handleCTAClick = e => {
    if (["guide", "set-up-call"].includes(action)) {
      e.preventDefault();
      handleOpenModal();
    }
  };

  const fixedFooterClasses = classNames("journey-fixed-footer", {
    "is-scrolled": isScrolled
  });

  return (
    <div className={fixedFooterClasses}>
      <a
        id={clickId || ""}
        href={ctaUrl}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleCTAClick}
      >
        <div className="fixed-footer-button cta-button">
          <p>{text}</p>
        </div>
      </a>
    </div>
  );
};

export default FixedFooterCTA;
