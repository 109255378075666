import React from "react";
import qs from "querystring";
import classnames from "classnames";

import { nameString, getGatsbyImgSrc } from "../../../utils/utils";

import "./CTAItem.sass";

const CTAItem = ({
  introText,
  header,
  description,
  buttonText,
  image,
  imageUrl = "",
  action,
  memorialUrl,
  user,
  flowerUrl,
  handleOpenModal,
  type,
  quizConfig,
  partner,
  premiumProgram,
  trackingStage = "",
  clickId = ""
}) => {
  const { _id, name, email, phone } = user || {};
  const { quizUrl, sourceId } = quizConfig || {};

  const imgSrc = getGatsbyImgSrc(image);

  const queryString = qs.stringify({
    ...(user && { user: _id }),
    ...(action && { action }),
    skipMat: true
  });

  const memUrlWithQuery = memorialUrl
    ? `https://everdays.com${memorialUrl}?${queryString}`
    : "https://everdays.com";

  const quizQuery = qs.stringify({
    name: (name && name.first) || "",
    email,
    phone,
    userid: _id,
    stage: trackingStage,
    fullname: nameString(name),
    premiumProgram,
    partner,
    sourceId
  });

  let ctaUrl = memUrlWithQuery;
  switch (action) {
    case "flowers":
      ctaUrl = !!flowerUrl ? flowerUrl : memUrlWithQuery;
      break;
    case "quiz":
      ctaUrl = `${quizUrl}?${quizQuery}`;
      break;
    default:
      break;
  }

  const handleCTAClick = e => {
    if (type === "ctaImage") e.preventDefault();
    if (["guide", "set-up-call"].includes(action)) {
      e.preventDefault();
      handleOpenModal();
    }
  };

  const ctaItemClasses = classnames("cta-item", {
    "image-only": type === "ctaImage"
  });

  return (
    <div className={ctaItemClasses}>
      <div className="cta-content-container">
        {!!introText && <p className="intro-text">{introText}</p>}
        <a
          id={clickId || ""}
          className="cta-item-card"
          href={ctaUrl}
          target="_blank"
          rel="noreferrer noopener"
          onClick={handleCTAClick}
        >
          <img src={imageUrl || imgSrc} alt="journey cta item" />
          <h3>{header}</h3>
          <p>{description}</p>
          <button id={clickId ? `${clickId}-btn` : ""}>{buttonText}</button>
        </a>
      </div>
    </div>
  );
};

export default CTAItem;
