import React from "react";
import { APPLE_LINK, GOOGLE_LINK } from "../../../constants/urlConstants";
import classNames from "classnames";

import "./AppLinks.sass";

const bucket = "https://everdays-site.s3.amazonaws.com/share";

const appStoreBtn = `${bucket}/appstore-black.svg`;
const googlePlayBtn = `${bucket}/googleplay-black.svg`;

const appStoreIconWhite = `${bucket}/appstore-white.svg`;
const playStoreIconWhite = `${bucket}/googleplay-white.svg`;

const AppLinks = ({ white, isRow }) => {
  const downloadLinkClasses = classNames("app-modal-store-links", {
    "display-row": isRow
  });

  return (
    <div className={downloadLinkClasses}>
      {
        // (os === 'iOS' || !os) &&
        <a
          href={APPLE_LINK}
          target="_blank"
          rel="noopener noreferrer"
          // onClick={googleAnalytics('apple_store')}
        >
          <img
            className="app-modal-apple-store"
            src={white ? appStoreIconWhite : appStoreBtn}
            alt="Download Everdays on Apple App Store"
          />
        </a>
      }
      {
        // (os === 'AndroidOS' || !os) &&
        <a
          href={GOOGLE_LINK}
          target="_blank"
          rel="noopener noreferrer"
          // onClick={googleAnalytics('google_store')}
        >
          <img
            className="app-modal-google-store"
            src={white ? playStoreIconWhite : googlePlayBtn}
            alt="Download Everdays on Google Play Store"
          />
        </a>
      }
    </div>
  );
};

export default AppLinks;
