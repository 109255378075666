import React, { useState, useEffect } from "react";

import { nameString } from "../../../../../utils/utils";

import "./SetUpCallModalBody.sass";

import successIcon from "../../../../../img/icons/temp-success-icon.png";

const SetUpCallModalBody = ({
  exJourneyId,
  user,
  premiumProgram = "",
  buttonText = "Set Up A Call",
  guideConfig = {},
  onSubmitSuccess = () => {},
  onSubmitFailure = () => {},
  handleModalClose
}) => {
  const { name = "", email = "", phone = "" } = user || {};
  const { formName, mandrillTemplate, sourceId, tracking, assetUrl } =
    guideConfig || {};

  const [currentBody, setCurrentBody] = useState("set-up-call-form");
  const [formObj, setFormObj] = useState({
    name: "",
    email: "",
    phone: ""
  });

  // on mount, if all info is provided, show the info-known modal view
  useEffect(() => {
    if (!!name && !!email && !!phone) {
      setCurrentBody("info-known");
      handleFormSubmit();
    }
  }, [name, email, phone]);

  // pre-populate the form inputs with the user data if possible
  useEffect(() => {
    const newFormObj = {
      ...formObj,
      ...(name && { name: nameString(name) }),
      ...(email && { email }),
      ...(phone && { phone })
    };

    setFormObj(newFormObj);
    // eslint-disable-next-line
  }, [user, name, email, phone]); // omit formObj from dep array to prevent loop

  const handleChange = (e, key) => {
    const newFormObj = { ...formObj };
    newFormObj[key] = e.target.value;

    setFormObj(newFormObj);
  };

  const handleFormSubmit = async e => {
    !!e && e.preventDefault();
    const submitObj = {
      name: formObj.name || nameString(name),
      email: formObj.email || email,
      phone: formObj.phone || phone,
      id: exJourneyId,
      tos: true,
      form: formName,
      sourceId: Number.parseInt(sourceId),
      tracking,
      mandrillTemplate,
      ...(premiumProgram && { premiumProgram })
    };

    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(submitObj)
    };

    const res = await fetch(
      "https://everdays.com/public/journey/external/form",
      opts
    );
    if (res.status === 200) {
      onSubmitSuccess();
    } else {
      onSubmitFailure();
    }
  };

  const handleSend = e => {
    setCurrentBody("form-sent");
    handleFormSubmit(e);
  };

  const SetUpCallForm = (
    <form className="set-up-call-form" onSubmit={handleSend}>
      <div className="set-up-call-form-text-header">
        <h2>We look forward to speaking with you</h2>
        <p>
          Add your details below and we'll connect with you soon to answer your
          questions and see how we can help with a plan that's right for you.
        </p>
      </div>
      <label htmlFor="set-up-call-form-email">Email Address *</label>
      <input
        id="set-up-call-form-email"
        name="email"
        type="email"
        placeholder="Your Email"
        value={formObj.email}
        onChange={e => handleChange(e, "email")}
        required
      />
      <label htmlFor="set-up-call-form-name">Name</label>
      <input
        id="set-up-call-form-name"
        name="name"
        placeholder="Your Name"
        value={formObj.name}
        onChange={e => handleChange(e, "name")}
      />
      <label htmlFor="set-up-call-form-phone">Phone</label>
      <input
        id="set-up-call-form-phone"
        name="phone"
        type="tel"
        placeholder="Your Phone Number"
        value={formObj.phone}
        onChange={e => handleChange(e, "phone")}
      />
      <button type="submit">{buttonText}</button>
    </form>
  );

  const FormSent = (
    <div className="form-sent">
      <h2>Thank You!</h2>
      <img
        className="success-icon"
        src={successIcon}
        alt="Form Sent Success Icon"
      />
      <p>
        We've received your request and one of our planning team members will
        follow up with you soon.
      </p>
      <button onClick={handleModalClose}>Done</button>
    </div>
  );

  const InfoKnownBody = (
    <div className="info-known-body">
      <h2>We look forward to speaking with you</h2>
      <p>
        We'll connect with you soon to answer your questions and see how we can
        help with a plan that's right for you.
      </p>
    </div>
  );

  const renderModalBody = () => {
    switch (currentBody) {
      case "set-up-call-form":
        return SetUpCallForm;
      case "form-sent":
        return FormSent;
      case "info-known":
        return InfoKnownBody;
      default:
        break;
    }
  };

  return <div className="set-up-call-modal-body">{renderModalBody()}</div>;
};

export default SetUpCallModalBody;
