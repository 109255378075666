import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { graphql } from "gatsby";
import SEO from "../components/Helmet";

import JourneyWrapper from "../components/Journeys/JourneyWrapper";
import JourneyNav from "../components/Journeys/JourneyNav";
import ContentHeader from "../components/Journeys/ContentHeader";
import Sender from "../components/Journeys/Sender";
import FixedFooterCTA from "../components/Journeys/FixedFooterCTA";

import ContentItem from "../components/Journeys/ContentItem";
import CTAItem from "../components/Journeys/CTAItem";
import ButtonLink from "../components/Journeys/ButtonLink";
import AdCTA from "../components/Journeys/AdCTA";
import QuoteComponent from "../components/Journeys/QuoteComponent";
import Carousel from "../components/Journeys/Carousel";
import Infographic from "../components/Journeys/Infographic";

import Footer from "../components/shared/Footer";

import GuideModalComponent from "../components/Modals/FullModals/GuideModalComponent";
import SetUpCallModalComponent from "../components/Modals/FullModals/SetUpCallModalComponent";

import { getGatsbyImgSrc } from "../utils/utils";

import "./styles/journey-page.sass";

import evdyLogo from "../img/logos/everdays-logo-full-white.svg";

export const JourneyPage = ({
  flowerUrl,
  templateData = {},
  journey,
  memorial,
  memorialRoute,
  user,
  isMobile,
  isScrolled,
  exJourneyId
}) => {
  const [isGuideFormOpen, setGuideFormOpen] = useState(false);
  const [isSetUpCallFormOpen, setSetUpCallFormOpen] = useState(false);

  const { frontmatter } = templateData.markdownRemark || {};
  const {
    headerNavBarObj,
    heroImgObj,
    contentHeaderObj,
    fixedFooterCTA,
    contentList = [],
    guideConfig = {},
    quizConfig = {},
    seoConfig
  } = frontmatter || {};

  // header obj
  const { clickId: navClickId = "" } = headerNavBarObj || {};

  // hero obj
  const { heroImg } = heroImgObj || {};
  const heroImgUrl = getGatsbyImgSrc(heroImg);

  // content header obj
  const { headerText, headerImg } = contentHeaderObj || {};

  // memorial obj
  const { name: decedentName, image: memorialImg, funeralHome = {} } =
    memorial || {};
  const { trackingStage } = journey || {};
  const { partner = "", premiumProgram = "" } = funeralHome || {};

  // fixed footer obj
  const {
    text: footerCTAText,
    action: footerCTAAction,
    image: footerCTAImg,
    clickId: footerClickId,
    isHidden
  } = fixedFooterCTA || {};

  // seo obj
  const { title = "", description = "", image: seoImg } = seoConfig || {};
  const seoImgUrl = getGatsbyImgSrc(seoImg);

  // booleans for displaying content
  const shouldDisplayContentHeader = headerText || headerImg;
  const shouldDisplayFixedFooter =
    !isHidden && footerCTAText && footerCTAAction;

  // class definitions
  const journeyPageClasses = classnames("journey-page", {
    "no-hero": !heroImgUrl,
    "no-fixed-footer": !shouldDisplayFixedFooter
  });

  const contentArray = contentList || [];

  const ctaProps = {
    user,
    memorialUrl: memorialRoute,
    flowerUrl,
    guideConfig,
    quizConfig,
    partner,
    premiumProgram,
    trackingStage
  };

  const handleOpenModal = action => {
    switch (action) {
      case "guide":
        return setGuideFormOpen(true);
      case "set-up-call":
        return setSetUpCallFormOpen(true);
      default:
        break;
    }
  };

  const renderContentItem = (item, i) => {
    switch (item.type) {
      case "contentItem":
      case "textBlock":
        return <ContentItem isFirstItem={i === 0} key={item.title} {...item} />;
      case "ctaItem":
        return (
          <CTAItem
            {...item}
            {...ctaProps}
            key={`${item.action}-${i}`}
            handleOpenModal={() => handleOpenModal(item.action)}
          />
        );
      case "buttonLink":
        return (
          <ButtonLink
            {...ctaProps}
            {...item}
            key={`${item.text}-${item.url}`}
            handleOpenModal={() => handleOpenModal(item.action)}
          />
        );
      case "adCTA":
        return (
          <AdCTA
            {...item}
            {...ctaProps}
            key={`${item.buttonText}-${item.buttonUrl}`}
            isMobile={isMobile}
            handleOpenModal={() => handleOpenModal(item.buttonAction)}
          />
        );
      case "quote":
        return <QuoteComponent {...item} />;
      case "imageCarousel":
        return <Carousel {...item} isMobile={isMobile} />;
      case "infographic":
        return <Infographic {...item} />;
      default:
        break;
    }
  };

  return (
    <div className={journeyPageClasses}>
      <SEO {...{ title, description }} image={heroImgUrl || seoImgUrl} />
      <JourneyNav
        {...{
          decedentName,
          memorialImg,
          user,
          flowerUrl,
          isScrolled,
          navClickId
        }}
        memorialUrl={memorialRoute}
        action={footerCTAAction}
        text={footerCTAText}
        handleOpenModal={() => handleOpenModal(footerCTAAction)}
        ctaClickId={footerClickId}
      />
      {heroImgUrl && (
        <div
          className="fixed-hero-image"
          style={{ backgroundImage: `url('${heroImgUrl}')` }}
        >
          <img
            className="logo-overlay"
            src={evdyLogo}
            alt="Everdays logo overlay"
          />
        </div>
      )}
      <div className="journey-page-content">
        {shouldDisplayContentHeader && (
          <ContentHeader {...{ headerText, headerImg }} />
        )}
        <Sender {...{ journey }} />
        {contentArray.map(renderContentItem)}
      </div>
      <Footer />
      {shouldDisplayFixedFooter && (
        <FixedFooterCTA
          {...{ decedentName, memorialImg, user, flowerUrl, isScrolled }}
          action={footerCTAAction}
          image={footerCTAImg}
          memorialUrl={memorialRoute}
          text={footerCTAText}
          handleOpenModal={() => handleOpenModal(footerCTAAction)}
        />
      )}
      {isGuideFormOpen && (
        <GuideModalComponent
          {...{
            user,
            journey,
            exJourneyId,
            guideConfig,
            partner,
            premiumProgram
          }}
          handleModalClose={() => setGuideFormOpen(false)}
          headerType="text"
        />
      )}
      {isSetUpCallFormOpen && (
        <SetUpCallModalComponent
          {...{
            user,
            exJourneyId,
            guideConfig,
            premiumProgram
          }}
          handleModalClose={() => setSetUpCallFormOpen(false)}
        />
      )}
    </div>
  );
};

const JourneyTemplate = ({ data }) => (
  <JourneyWrapper>
    <JourneyPage templateData={data} />
  </JourneyWrapper>
);

JourneyTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export default JourneyTemplate;

export const journeyPageQuery = graphql`
  query JourneyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        headerNavBarObj {
          clickId
        }
        heroImgObj {
          heroImg {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contentHeaderObj {
          headerText
          headerImg {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contentList {
          introText
          title
          clickId
          header
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content
          action
          type
          text
          url
          buttonText
          buttonUrl
          buttonAction
          quoteText
          quoteImg {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image4 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image5 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          infographicImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedFooterCTA {
          text
          action
          clickId
          isHidden
        }
        guideConfig {
          assetUrl
          formName
          sourceId
          tracking
          mandrillTemplate
        }
        quizConfig {
          quizUrl
          sourceId
        }
        seoConfig {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
