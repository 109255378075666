import React from "react";

import { getGatsbyImgSrc } from "../../../utils/utils";

import "./ContentHeader.sass";

const ContentHeader = ({ headerText, headerImg }) => {
  const imgUrl = getGatsbyImgSrc(headerImg);

  return (
    <div className="content-header">
      {!!imgUrl && <img src={imgUrl} alt="Content Header Icon" />}
      <h2>{headerText}</h2>
    </div>
  );
};

export default ContentHeader;
